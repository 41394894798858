<template>
  <ul>
    <li 
      v-for="item in items"
      :key="item.id"
      class="cursor-pointer border-dashed border-b border-grey-darker-200"
    >
      <div class="flex items-center justify-between py-3">
        <span class="text-base base-font-gray-200"> 
          {{ item.title }} 
        </span>
        <toggleBtn 
          :options="{
            isActive: item.state,
            track: {
              activeColor: '#4F23FF',
              width: 64,
              height: 32
            },
            handle: {
              diameter: 26,
              distance: 30
            }
          }"
          @setIsActive="changeState({
            event: $event, 
            id: item.id
          })"
        />
      </div>
    </li>
  </ul>
</template>

<script>
  import toggleBtn from '@/components/buttons/toggle-btn'

  export default {
    components: { toggleBtn },
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      changeState(arg){
        this.$emit('changeState', arg)
      }
    },
  }
</script>

<style lang="scss" scoped>
.active-item{
  color: #202020!important;
}
</style>
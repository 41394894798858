var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',_vm._l((_vm.items),function(item){return _c('li',{key:item.id,staticClass:"cursor-pointer border-dashed border-b border-grey-darker-200"},[_c('div',{staticClass:"flex items-center justify-between py-3"},[_c('span',{staticClass:"text-base base-font-gray-200"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('toggleBtn',{attrs:{"options":{
          isActive: item.state,
          track: {
            activeColor: '#4F23FF',
            width: 64,
            height: 32
          },
          handle: {
            diameter: 26,
            distance: 30
          }
        }},on:{"setIsActive":function($event){return _vm.changeState({
          event: $event, 
          id: item.id
        })}}})],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }